import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import React from 'react'
import { Box, Typography } from '@mui/material'

interface MiarikaTimelineEntry<T extends string> {
  type: T
}

interface MiarikaTimelineEvent extends MiarikaTimelineEntry<'event'> {
  date: Date
  text: string
  miaQuotes?: string[]
  erikaQuotes?: string[]
}

interface MiarikaTimelineCustom extends MiarikaTimelineEntry<'custom'> {
  node: React.ReactNode
}

interface MiarikaTimelineQuote extends MiarikaTimelineEntry<'quote'> {
  miaQuote?: string
  erikaQuote?: string
}

interface MiarikaTimelineBlock extends MiarikaTimelineEntry<'block'> {
  quote: string
  speaker: string
}

export type MiarikaTimelineProps = {
  preConnector?: boolean
  postConnector?: boolean
  entries: (MiarikaTimelineEvent | MiarikaTimelineQuote | MiarikaTimelineCustom | MiarikaTimelineBlock)[]
}

function TimelineItemEvent({ event, connector }: { connector: boolean; event: MiarikaTimelineEvent }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography component="span" variant="h5">
          {event.date.toLocaleDateString()}
        </Typography>
        {event.miaQuotes && event.miaQuotes.map((quote) => <Typography>{`"${quote}" Mia`}</Typography>)}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        {connector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography component="span" variant="h5">
          {event.text}
        </Typography>
        {event.erikaQuotes && event.erikaQuotes.map((quote) => <Typography>{`"${quote}" Erika`}</Typography>)}
      </TimelineContent>
    </TimelineItem>
  )
}

function TimelineItemCustom(props: { connector: boolean; custom: MiarikaTimelineCustom }) {
  return [
    <TimelineItem>{props.custom.node}</TimelineItem>,
    <TimelineItem>
      <TimelineSeparator>{props.connector && <TimelineConnector />}</TimelineSeparator>
      <TimelineContent />
    </TimelineItem>,
  ]
}

function TimelineItemBlock({ connector, block }: { connector: boolean; block: MiarikaTimelineBlock }) {
  return [
    <TimelineItem>
      <TimelineOppositeContent />
      <Box sx={{ border: 2, borderStyle: 'dashed', width: '60%', borderColor: '#bdbdbd' }}>
        <Typography variant="h5">{block.quote}</Typography>
      </Box>
      <TimelineContent />
    </TimelineItem>,
    <TimelineItem>
      <TimelineSeparator>{connector && <TimelineConnector />}</TimelineSeparator>
      <TimelineContent />
    </TimelineItem>,
  ]
}

function TimelineItemQuote({ connector, quote }: { connector: boolean; quote: MiarikaTimelineQuote }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography component="span" variant="body1">
          {quote.miaQuote && `"${quote.miaQuote}" Mia`}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>{connector && <TimelineConnector />}</TimelineSeparator>
      <TimelineOppositeContent>{quote.erikaQuote && `"${quote.erikaQuote}" Erika`}</TimelineOppositeContent>
    </TimelineItem>
  )
}

export function MiarikaTimeline({ entries, preConnector, postConnector }: MiarikaTimelineProps) {
  return (
    <Timeline>
      {preConnector && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
          </TimelineSeparator>
        </TimelineItem>
      )}
      {entries.flatMap((entry, index) => {
        const connector = postConnector || entries.length !== index + 1
        if (entry.type === 'quote') {
          return <TimelineItemQuote connector={connector} quote={entry} />
        }
        if (entry.type === 'custom') {
          return TimelineItemCustom({ connector, custom: entry })
        }
        if (entry.type === 'block') {
          return TimelineItemBlock({ connector, block: entry })
        }
        return <TimelineItemEvent connector={connector} event={entry} />
      })}
    </Timeline>
  )
}
