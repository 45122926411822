import React from 'react'
import './App.css'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'
import { createTheme, ThemeProvider, Typography } from '@mui/material'
import { TimelineContent, TimelineOppositeContent } from '@mui/lab'
import { MiarikaTimeline } from './util/timeline'
import headerImage from './images/header-5120x.jpg'
import prideImage from './images/pride-parade.jpg'
import heartImage from './images/heart.svg'
import programmingImage from './images/website.jpg'
import ramenImage from './images/ramen.jpg'
import chekiImage from './images/cheki.jpg'

const theme = createTheme({
  typography: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Dancing Script', cursive;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Countryside'), url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap') format('tff');
        }
      `,
    },
  },
})

function App() {
  return (
    <div className="App">
      <ParallaxProvider>
        <ThemeProvider theme={theme}>
          <header className="App-header">
            <Typography fontFamily="Dancing Script" variant="h1">
              Erika and Mia
            </Typography>
            <Typography fontFamily="Dancing Script" variant="h3">
              The story of how they fell in love
            </Typography>
            <ParallaxBanner
              style={{ height: '300px' }}
              layers={[{ image: headerImage, speed: -15 }]}
              className="aspect-[2/1]"
            />
            <MiarikaTimeline
              entries={[
                {
                  type: 'event',
                  date: new Date(2023, 3, 3),
                  text: 'Meet at bar',
                  miaQuotes: ['Hey we should swap line info'],
                },
                {
                  type: 'event',
                  date: new Date(2023, 3, 9),
                  text: 'Go to dog festival',
                  erikaQuotes: [
                    "Please feel welcome to join pride.  The more the merrier and it'll be a fun way to hang and get to know each other.",
                  ],
                },
                {
                  type: 'event',
                  date: new Date(2023, 3, 23),
                  text: 'Go to pride festival',
                },
                {
                  type: 'custom',
                  node: [
                    <ParallaxBanner
                      style={{ height: '300px' }}
                      layers={[{ image: prideImage, speed: -2 }]}
                      className="aspect-[2/1]"
                    />,
                  ],
                },
                {
                  type: 'quote',
                  erikaQuote: 'What a blessing that we were able to meet early in your Japan life journey.',
                },
                {
                  type: 'quote',
                  miaQuote: 'The Shinto japanese spirits guided us together.',
                },
                {
                  type: 'event',
                  date: new Date(2023, 4, 3),
                  text: 'House party and first cry session',
                },
                {
                  type: 'event',
                  date: new Date(2023, 4, 5),
                  text: 'Build first website together',
                },
                {
                  type: 'custom',
                  node: [
                    <ParallaxBanner
                      style={{ height: '300px' }}
                      layers={[{ image: programmingImage, speed: -3 }]}
                      className="aspect-[2/1]"
                    />,
                  ],
                },
                {
                  type: 'event',
                  date: new Date(2023, 4, 14),
                  text: 'Girls shopping day',
                },
                {
                  type: 'event',
                  date: new Date(2023, 4, 26),
                  text: 'First solo dinner and hangout after work',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 8),
                  text: 'Aquarium gal pal date',
                },
                {
                  type: 'custom',
                  node: [
                    <ParallaxBanner
                      style={{ height: '300px' }}
                      layers={[{ image: ramenImage, speed: -3 }]}
                      className="aspect-[2/1]"
                    />,
                  ],
                },
                {
                  type: 'quote',
                  miaQuote: 'You should check out some of my favorite nhentai links.',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 9),
                  text: 'Video game cuddle night',
                },
                {
                  type: 'block',
                  speaker: 'Erika',
                  quote:
                    "You didn't notice but when you were talking me that way I started blushing and getting flustered.",
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 12),
                  text: 'Erika makes her first move',
                },
                {
                  type: 'quote',
                  erikaQuote: "I haven't watched the show yet but I'm preemptively claiming the lion girl as a waifu.",
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 15),
                  text: 'Do first "gal pal" activity',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 18),
                  text: 'MTV Pride Concert',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 19),
                  text: 'Mia runs to erika after shitty work day',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 20),
                  text: 'Video games and homework night',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 21),
                  text: 'Realize we have feelings',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 22),
                  text: 'Confess our love to each other',
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 24),
                  text: "Celebrate Mia's birthday",
                },
                {
                  type: 'event',
                  date: new Date(2023, 5, 25),
                  text: 'Cemented start of "gal pal" relationship',
                },
                {
                  type: 'custom',
                  node: [
                    <ParallaxBanner
                      style={{ height: '300px' }}
                      layers={[{ image: chekiImage, speed: -3 }]}
                      className="aspect-[2/1]"
                    />,
                  ],
                },
                {
                  type: 'custom',
                  node: [
                    <TimelineOppositeContent />,
                    <img
                      src={heartImage}
                      style={{ display: 'block', margin: 'auto', width: '50px', height: '50px' }}
                      alt="heart"
                    />,
                    <TimelineContent />,
                  ],
                },
                {
                  type: 'event',
                  date: new Date(2023, 10, 22),
                  text: 'Erika proposed. Mia said yes and then they had a private wedding ceremony.',
                },
              ]}
            />
          </header>
        </ThemeProvider>
      </ParallaxProvider>
    </div>
  )
}

export default App
